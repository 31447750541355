<template>
  <div class="view-root">
    <h3>嘉宾列表</h3>
    <div style="text-align: right">
      <el-button type="primary" size="small" @click="editGuestInfo(null)"
        >添加嘉宾</el-button
      >
    </div>
    <el-table :data="dataList" style="margin-top: 15px">
      <el-table-column type="index" prop="index" label="序号"></el-table-column>
      <el-table-column prop="name" label="嘉宾姓名"></el-table-column>
      <el-table-column prop="role" label="嘉宾角色"></el-table-column>
      <el-table-column prop="" label="头衔">
        <template slot-scope="scope">
          <div style="display: flex; flex-wrap: wrap; gap: 5px">
            <el-tag
              v-for="(title, index) in scope.row.titles"
              :key="index"
              type="success"
              >{{ title }}</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="company" label="所在公司"></el-table-column>
      <el-table-column prop="" label="头像">
        <template slot-scope="scope">
          <img
            :src="scope.row.avatar_url"
            style="width: 72px; height: 72px; object-fit: cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="email" label="登录账号"></el-table-column>
      <el-table-column prop="password" label="密码"></el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="editGuestInfo(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="removeGuestInfo(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="showGuestDialog"
      :title="guestInfo.id == '' ? '添加嘉宾' : '编辑嘉宾'"
    >
      <div>
        <el-form :model="guestInfo" :rules="rules" ref="guestInfo">
          <el-form-item label="嘉宾姓名" prop="name">
            <el-input v-model="guestInfo.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="头像">
            <el-upload
              class="avatar-uploader"
              action="/"
              :show-file-list="false"
              :before-upload="(file) => beforeAvatarUpload(file)"
            >
              <img
                v-if="guestInfo.avatar_url"
                :src="guestInfo.avatar_url"
                style="object-fit: cover"
                class="el-icon-plus avatar-uploader-icon"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="嘉宾角色" prop="role">
            <el-select v-model="guestInfo.role" placeholder="请选择">
              <el-option label="主持人" value="主持人"></el-option>
              <el-option label="演讲人" value="演讲人"></el-option>
              <el-option label="分享人" value="分享人"></el-option>
              <el-option label="致辞人" value="致辞人"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <span>头衔</span>
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              placeholder="请输入"
              @tags-changed="(newTags) => (tags = newTags)"
            />
          </el-form-item>
          <el-form-item label="所在公司" prop="">

            <el-select v-model="guestInfo.company" placeholder="请选择" style="width: 400px;">
                <el-option v-for="(companyName, index) in companyArr" :key="index"
                    :label="companyName.name"
                    :value="companyName.name"></el-option>
            </el-select>

            <!-- <el-input
              v-model="guestInfo.company"
              placeholder="请输入"
            ></el-input> -->

          </el-form-item>
          <el-form-item label="">
            <label>单位介绍</label>
            <quill-editor
              class="editor"
              style="margin-bottom: 20px"
              ref="myTextEditor"
              v-model="guestInfo.company_intro"
              :options="editorOption"
            >
            </quill-editor>
          </el-form-item>

          <el-form-item label="">
            <label>简介</label>
            <quill-editor
              class="editor"
              style="margin-bottom: 20px"
              ref="myTextEditor"
              v-model="guestInfo.intro"
              :options="editorOption"
            >
            </quill-editor>
          </el-form-item>
          <el-form-item label="登录账号">
            <el-input v-model="guestInfo.email" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              v-model="guestInfo.password"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showGuestDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveGuestInfo()">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getQiniuToken,
  getGuests,
  removeGuest,
  updateGuest,
  getbrandPartys
} from "../../api/api";
import { getUserId } from "../../utils/store.js";
import "../../assets/common/common.css";
import * as qiniu from "qiniu-js";
import VueTagsInput from "@johmun/vue-tags-input";
export default {
  name: "index",
  components: {
    VueTagsInput,
  },
  data() {
    return {
      // session_id: this.$route.query.session_id,
      dataList: [],
      tag: "",
      tags: [],
      guestInfo: {
        id: "",
        // session_id: this.session_id,
        name: "",
        titles: [],
        company: "",
        intro: "",
        avatar_url: "",
        role: "",
        company_intro: "",
      },
      rules: {
        role: [{ required: true, message: "Please input", trigger: "blur" }],
        name: [{ required: true, message: "Please input", trigger: "blur" }],
        title: [{ required: true, message: "Please input", trigger: "blur" }],
        company: [{ required: true, message: "Please input", trigger: "blur" }],
        intro: [{ required: true, message: "Please input", trigger: "blur" }],
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
      showGuestDialog: false,
      companyArr:[]
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getGuests().then((res) => {
        this.dataList = res.data.data;
      });

      getbrandPartys().then((res) => {
        this.companyArr = res.data.data;
      });

      
    },
    async beforeAvatarUpload(file) {
      const result = await getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            this.guestInfo.avatar_url =
              "http://mainbucket.reachable-edu.com/" + res.key;
            console.log("url:" + this.guestInfo.avatar_url);
          },
        };
        this.observable.subscribe(observer);
      }
    },
    removeGuestInfo(row) {
      this.$alert("你确定要删除此嘉宾吗？", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            removeGuest(row._id.$id).then((res) => {
              this.$message.success("删除成功");
              this.fetchData();
            });
          }
        },
      });
    },
    editGuestInfo(row) {
      this.tag = "";
      this.tags = [];
      if (row) {
        this.guestInfo = {
          id: row._id.$id,
          // session_id: row.session_id,
          name: row.name,
          company: row.company,
          intro: row.intro,
          avatar_url: row.avatar_url,
          role: row.role,
          company_intro: row.company_intro,
          email: row.email,
          password: row.password,
        };
        if (row.titles) {
          var temp_tags = row.titles;
          for (var i = 0; i < temp_tags.length; i++) {
            var tag = { text: temp_tags[i] };
            this.tags.push(tag);
          }
        }
      } else {
        this.guestInfo = {
          id: "",
          name: "",
          company: "",
          intro: "",
          // session_id: this.session_id,
          avatar_url: "",
          role: "",
          company_intro: "",
          email: "",
          password: "",
        };
      }
      this.showGuestDialog = true;
    },
    saveGuestInfo() {
      var titles = [];
      for (var i = 0; i < this.tags.length; i++) {
        titles.push(this.tags[i].text);
      }
      if (titles.length <= 0) {
        this.$message.error("请填写头衔");
        return;
      }
      this.guestInfo.titles = titles;
      this.guestInfo.organizer_id = getUserId();
      updateGuest(this.guestInfo).then((res) => {
        this.$message.success("保存成功");
        this.fetchData();
        this.showGuestDialog = false;
      });
    },
  },
};
</script>
<style>
.ql-container {
  height: 160px !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
